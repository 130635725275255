const timerEl = document.querySelector('.timer')
const timerDisplayDays = document.querySelector('.timer__time .days-left')
const timerDisplayDaysDesc = document.querySelector('.timer__time .days-left-desc')
const timerDisplayHours = document.querySelector('.timer__time .hours-left')
const timerDisplayHoursDesc = document.querySelector('.timer__time .hours-left-desc')
const timerDisplayMin = document.querySelector('.timer__time .minutes-left')
const timerDisplayMinDesc = document.querySelector('.timer__time .minutes-left-desc')
const timerDisplaySec = document.querySelector('.timer__time .seconds-left')
const timerDisplaySecDesc = document.querySelector('.timer__time .seconds-left-desc')
const timerEndDisplay = document.querySelector('.timer__time')

let countdown

// start here
if (timerEl) {
    const timeEnd = parseInt(timerEl.dataset.date)
    startTimer(timeEnd)

    setInterval(() => {
        timerEl.classList.add('timer__unhide')
    }, 1500)
}

// run the timer
function startTimer (seconds) {
    const timerEndDate = new Date(seconds)
    timer(timerEndDate)
}

// seconds is input
function timer (seconds) {
    clearInterval(countdown)
    const then = seconds * 1000

    // every 1000ms/1sec count down
    countdown = setInterval(() => {
        const secondsLeft = Math.round((then - Date.now()) / 1000)
        // check if we should stop it
        if (secondsLeft <= 0) {
            clearInterval(countdown)
            displayEndTxt(secondsLeft)
            return
        }

        // display it
        displayTimeLeft(secondsLeft)
    }, 1000)
}

function displayTimeLeft (seconds) {
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    const remainderSeconds = seconds % 60
    const adjustedHours = hours > 24 ? hours - (days * 24) : hours
    const adjustedMinutes = minutes > 60 ? minutes - (hours * 60) : minutes

    // display days
    timerDisplayDays.textContent = `${days}`
    timerDisplayDaysDesc.textContent = `${days > 1 ? ' days' : ' day'}`

    // display hours
    timerDisplayHours.textContent = `${adjustedHours > 24 ? adjustedHours - 24 : adjustedHours}`
    timerDisplayHoursDesc.textContent = `${adjustedHours > 1 ? ' hours' : ' hour'}`

    // display minutes
    timerDisplayMin.textContent = `${adjustedMinutes}`
    timerDisplayMinDesc.textContent = `${adjustedMinutes > 1 ? ' minutes' : ' minute'}`

    // display seconds
    timerDisplaySec.textContent = `${remainderSeconds < 10 ? '0' : ''}${remainderSeconds}`
    timerDisplaySecDesc.textContent = `${remainderSeconds > 1 ? ' seconds' : ' second'}`

    function windowSize () {
        if (window.innerWidth <= 640) {
            timerDisplaySec.classList.add('hide')
            timerDisplaySecDesc.classList.add('hide')
        }
        if (window.innerWidth > 640) {
            timerDisplaySec.classList.remove('hide')
            timerDisplaySecDesc.classList.remove('hide')
        }
    }
    windowSize()
    window.addEventListener('resize', windowSize)
}

function displayEndTxt (seconds) {
    timerEndDisplay.textContent = 'Now'
}
