(($) => {
    $('.expand__button').on('click', function () {
        $(this).toggleClass('open')
        $(this).text('Collapse Chart')

        $('.box').toggleClass('open')

        if (!$(this).hasClass('open'))
            $(this).text('Expand Chart')
    })
})(window.jQuery)
