(($) => {
    if (!document.getElementById('news__year'))
        return

    let total = $('#total').val()
    let page = 2
    let year = $('#paramYear').val()
    let params = $('#paramFilters').val()

    $('#loadMore').click(function (e) {
        e.preventDefault()
        document.getElementById('loading').style.display = 'block'

        let queryUrl = '/news/p' + page
        if (year !== '' && params !== '')
            queryUrl += '?y=' + year + '&f=' + params
        else if (year !== '')
            queryUrl += '?y=' + year
        else if (params !== '')
            queryUrl += '?f=' + params

        $.get(queryUrl, function (data) {
            $('#news-entries').append(data)
            document.getElementById('loading').style.display = 'none'
            const nextArticle = (page - 1) * 10
            document.getElementsByClassName('news__article-title')[nextArticle].focus()
            page++

            if (page > total)
                document.getElementById('loadMore').style.display = 'none'
            else
                document.getElementById('loadMore').style.display = 'inline'
        })
    })

    $('#news__filter-button').click(function (e) {
        e.preventDefault()
        params = ''
        year = ''
        // console.log($('#news__year .selectize-input .item').html())
        if ($('#news__year .selectize-input .item').html() !== 'Year')
            year = $('#news__year .selectize-input .item').html()
        // console.log(year)
        const checkboxes = Array.from(document.getElementsByClassName('news__checkbox'))

        checkboxes.map((c, key) => {
            if (c.getElementsByTagName('input')[0].checked) {
                const param = c.getAttribute('name')
                params = params + param + ','
            }
        })
        params = params !== '' ? params.substring(0, params.length - 1) : ''

        if (params !== '' && $('#news__year .selectize-input .item').html() !== 'Year')
            $.get('/news?y=' + year + '&f=' + params, function (data) {
                $('#news-entries').empty()
                $('#news-entries').append(data)

                total = $('#total').val()
                if (total <= 1)
                    document.getElementById('loadMore').style.display = 'none'
                else
                    document.getElementById('loadMore').style.display = 'inline'
            })
        else if (params !== '')
            $.get('/news?f=' + params, function (data) {
                $('#news-entries').empty()
                $('#news-entries').append(data)

                total = $('#total').val()

                if (total <= 1)
                    document.getElementById('loadMore').style.display = 'none'
                else
                    document.getElementById('loadMore').style.display = 'inline'
            })
        else if ($('#news__year .selectize-input .item').html() !== 'Year')
            $.get('/news?y=' + year, function (data) {
                $('#news-entries').empty()
                $('#news-entries').append(data)

                total = $('#total').val()
                if (total <= 1)
                    document.getElementById('loadMore').style.display = 'none'
                else
                    document.getElementById('loadMore').style.display = 'inline'
            })
        else
            $.get('/news', function (data) {
                $('#news-entries').empty()
                $('#news-entries').append(data)

                total = $('#total').val()
                if (total <= 1)
                    document.getElementById('loadMore').style.display = 'none'
                else
                    document.getElementById('loadMore').style.display = 'inline'
            })

        if (window.innerWidth <= 960) {
            $('.news__right').removeClass('active')
            const resultsHeight = $('.news__main').offset().top - 80
            window.scrollTo(0, resultsHeight)
        }
    })

    const icon = document.getElementsByClassName('news__mobile-icon')[0]
    const filterArea = document.getElementsByClassName('news__right')[0]
    // console.log(filterArea.classList)
    icon.addEventListener('click', () => {
        if (filterArea.classList.contains('active')) {
            filterArea.classList.remove('active')
            icon.setAttribute('aria-expanded', 'false')
        }
        else {
            filterArea.classList.add('active')
            icon.setAttribute('aria-expanded', 'true')
        }
    })
    icon.addEventListener('keypress', (e) => {
        if (e.charCode === 13)
            if (filterArea.classList.contains('active')) {
                filterArea.classList.remove('active')
                icon.setAttribute('aria-expanded', 'false')
            }
            else {
                filterArea.classList.add('active')
                icon.setAttribute('aria-expanded', 'true')
            }
    })
})(window.jQuery)
