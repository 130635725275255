const sections = document.getElementsByClassName('adm__section')

if (sections.length) {
    const height = window.innerHeight

    const jsIconList = document.getElementsByClassName('js__icon-list')
    const jsColsHero = document.getElementsByClassName('js__richtextcols-hero')
    const jsHero = document.getElementsByClassName('js__richtext-hero')

    // add animations for each section appearing
    window.addEventListener('scroll', () => {
        Array.from(sections).map((section, key) => {
            const pageTop = section.getBoundingClientRect().top

            if (pageTop < height / 4 * 3 + 80)
                if (!section.classList.contains('active')) {
                    section.classList.add('active')
                    // switch (key) {
                    // // Rich Text Cols Hero
                    // case 1:
                    //     for (let i = 0; i < jsColsHero.length; i++)
                    //         setTimeout(function () {
                    //             jsColsHero[i].classList.add('active')
                    //         }, 100 * i)
                    //
                    //     break
                    // // Rich Text Hero
                    // case 2:
                    //     for (let i = 0; i < jsHero.length; i++)
                    //         setTimeout(function () {
                    //             jsHero[i].classList.add('active')
                    //         }, 100 * i)
                    //
                    //     break
                    // // Icon List
                    // case 4:
                    //     for (let i = 0; i < jsIconList.length; i++)
                    //         setTimeout(function () {
                    //             jsIconList[i].classList.add('active')
                    //         }, 100 * i)
                    //
                    //     break
                    // }
                }
        })
    })
}
