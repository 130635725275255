(() => {
    const formConditionalElementAttr = 'data-form-conditional-element'

    const parseConfig = (el) => {
        let data = {}

        if (!el)
            return data

        try {
            data = JSON.parse(el
                .getAttribute(formConditionalElementAttr) || '{}')
        }
        catch (e) {
            console.warn(e)
        }

        return data
    }

    const formConditionalElementInit = () => Array
        .from(document.querySelectorAll(`[${
            formConditionalElementAttr}]:not([${
            formConditionalElementAttr}-listener])`))
        .map((el) => ({
            config: parseConfig(el),
            el: el,
            wrapperEl: (() => {
                let _el = el

                while (_el.parentNode
                        && _el.parentNode !== document.body) {
                    _el = _el.parentNode

                    if (_el.classList
                        .contains('form-conditional-element__wrapper'))
                        return _el
                }

                return null
            })()
        }))
        .filter(obj =>
            !obj.el.getAttribute(`${formConditionalElementAttr}-listener`)
                && obj.config.selector
                && ('value' in obj.config
                    || 'checked' in obj.config))
        .forEach((obj) => {
            obj.el.setAttribute(`${formConditionalElementAttr}-listener`, true)

            const config = obj.config
            const el = obj.el
            const dependentEl = document.querySelector(config.selector)
            const wrapperEl = obj.wrapperEl

            if (!dependentEl)
                return

            if (dependentEl.id)
                el.setAttribute('data-dependent-selector', `#${dependentEl.id}`)

            dependentEl
                .addEventListener('change', (e) => {
                    const isEnable = !dependentEl.disabled
                        && (dependentEl.value === config.value
                            || (typeof config.value === 'boolean'
                                && dependentEl.value)
                            || ('checked' in config
                                && dependentEl.checked
                                    === config.checked)
                            || (config.regExp
                                && typeof config.value === 'string'
                                && dependentEl.value.match(
                                    new RegExp(config.value,
                                        config.regExpFlags || ''))))

                    if ((isEnable && el.disabled)
                            || (!isEnable && !el.disabled))
                        el.disabled = !el.disabled

                    const wrapperHiddenClassname
                        = 'form-conditional-element__wrapper--hidden'

                    if (el.getAttribute('data-form-conditional-element-hide')
                                !== 'false'
                            && ((isEnable
                                    && wrapperEl
                                    && wrapperEl.classList
                                        .contains(wrapperHiddenClassname))
                                || (!isEnable
                                    && wrapperEl
                                    && !wrapperEl.classList
                                        .contains(wrapperHiddenClassname))))
                        wrapperEl.classList.toggle(wrapperHiddenClassname)

                    if (!el.id
                            || !document.querySelector(
                                `[data-dependent-selector="#${el.id}"]`))
                        return

                    const evt = !('Event' in window)
                        ? document.createEvent('Event')
                        : new window.Event('change',
                            { view: window, bubbles: true })

                    if (!('Event' in window))
                        evt.initEvent('change', true, true)

                    el.dispatchEvent(evt)
                })
        })

    window.addEventListener('load', () => {
        formConditionalElementInit()

        const formConditionalElementObserver = new window
            .MutationObserver((mutations) => {
                if (!document.querySelector(`[${
                    formConditionalElementAttr}]:not([${
                    formConditionalElementAttr}-listener])`))
                    return

                formConditionalElementInit()
            })

        formConditionalElementObserver
            .observe(document.body, {
                childList: true,
                subtree: true
            })
    })
})()
