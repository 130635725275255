// import smoothscroll from 'smoothscroll-polyfill'

//smoothscroll.polyfill()

const arrowNodesM = document.getElementsByClassName('moments__arrow')
const backNodesM = document.getElementsByClassName('moments__arrow-back')
const momentNodes = document.getElementsByClassName('moments__list')

let scrolledM = 0

if (arrowNodesM.length > 0) {
    const arrowsM = Array.from(arrowNodesM)
    const backsM = Array.from(backNodesM)
    const moments = Array.from(momentNodes)

    arrowsM.map((arrow, key) => {
        arrow.addEventListener('click', () => {
            if (moments[key].scrollLeft + moments[key].offsetWidth < moments[key].scrollWidth) {
                scrolledM += 230
            }

            moments[key].scroll({
                top: 0,
                left: scrolledM,
                behavior: 'smooth'
            })
        })

        arrow.addEventListener('keypress', (e) => {
            if (e.charCode === 13) {
                if (moments[key].scrollLeft + moments[key].offsetWidth < moments[key].scrollWidth) {
                    scrolledM += 230
                }

                moments[key].scroll({
                    top: 0,
                    left: scrolledM,
                    behavior: 'smooth'
                })
            }
        })
    })

    backsM.map((arrow, key) => {
        arrow.addEventListener('click', () => {
            if (scrolledM > 0) {
                scrolledM -= 230
            }

            moments[key].scroll({
                top: 0,
                left: scrolledM,
                behavior: 'smooth'
            })
        })

        arrow.addEventListener('keypress', (e) => {
            if (e.charCode === 13) {
                if (scrolledM > 0) {
                    scrolledM -= 230
                }

                moments[key].scroll({
                    top: 0,
                    left: scrolledM,
                    behavior: 'smooth'
                })
            }
        })
    })
}
