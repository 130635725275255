// for accessibility purposes
const textBlocks = document.getElementsByClassName('rich-text__text')

if (textBlocks.length)
    Array.from(textBlocks).map(tb => {
        const links = tb.querySelectorAll("a[target='_blank']")
        Array.from(links).map(link => {
            link.setAttribute('aria-label', link.innerText + ' - opens in a new tab')
        })
    })
