const slideshowNodes = document.querySelectorAll('.slideshow-grid__wrapper')
// console.log(slideshowNodes.length)
if(slideshowNodes.length >= 1) {
    slideshowNodes.forEach((slideshow) => {
        // console.log(slideshow)
        slideshows(slideshow)
    })
}
function slideshows(element) {

    const imageNodes = element.getElementsByClassName('slideshow__image')
    const captionNodes = element.getElementsByClassName('slideshow__caption')
    const imageGrids = element.getElementsByClassName('image-grid__image')

    if (imageNodes.length > 1) {
        const images = Array.from(imageNodes)
        const captions = Array.from(captionNodes)
        const back = element.getElementsByClassName('slideshow__back')[0]
        const next = element.getElementsByClassName('slideshow__next')[0]
        const tracker = element.getElementsByClassName('slideshow__counter-current')[0]
        const thumbnails = element.getElementsByClassName('slideshow__icon')[0]
        const slideshow = element.getElementsByClassName('slideshow__wrapper')[0]
        const grid = element.getElementsByClassName('image-grid__wrapper')[0]
        const gridImages = Array.from(imageGrids)

        let index = 0
        const max = images.length - 1

        const updateIndex = (direction) => {
            if (direction === 'next')
                if (index === max) {
                    index = 0
                }
                else {
                    index++
                }

            if (direction === 'back')
                if (index === 0) {
                    index = max
                }
                else {
                    index--
                }
        }

        back.addEventListener('click', () => {
            images[index].classList.remove('active')
            captions[index].classList.remove('active')
            updateIndex('back')
            tracker.innerHTML = index + 1
            images[index].classList.add('active')
            captions[index].classList.add('active')
        })

        next.addEventListener('click', () => {
            images[index].classList.remove('active')
            captions[index].classList.remove('active')
            updateIndex('next')
            tracker.innerHTML = index + 1
            images[index].classList.add('active')
            captions[index].classList.add('active')
        })

        thumbnails.addEventListener('click', () => {
            slideshow.classList.remove('active')
            slideshow.classList.add('inactive')
            grid.classList.add('active')
            grid.classList.remove('inactive')
        })

        gridImages.map((image, key) => {
            image.addEventListener('click', () => {
                index = key
                tracker.innerHTML = index + 1
                grid.classList.remove('active')
                grid.classList.add('inactive')
                slideshow.classList.add('active')
                slideshow.classList.remove('inactive')

                images.map(i => {
                    i.classList.remove('active')
                })
                captions.map(c => {
                    c.classList.remove('active')
                })
                images[key].classList.add('active')
                captions[key].classList.add('active')
            })
        })

        captions.map(caption => {
            if (caption.innerText.includes('&amp;'))
                caption.innerText = caption.innerText.replace(/&amp;/g, '&')
        })
    }
}
