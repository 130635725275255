// import smoothscroll from 'smoothscroll-polyfill'

const eggNodes = document.getElementsByClassName('statistics__icon')

// require('smoothscroll-polyfill').polyfill()
// // import smoothscroll from 'smoothscroll-polyfill';
//smoothscroll.polyfill()

if (eggNodes.length > 0) {
    const eggs = Array.from(eggNodes)

    eggs.map((egg, key) => {
        egg.addEventListener('click', () => {
            const egg = document.getElementsByClassName('statistics__wrapper egg')[key]
            const contentWidth = egg.offsetWidth
            if (!egg.classList.contains('active')) {
                egg.scroll({
                    top: 0,
                    left: contentWidth,
                    behavior: 'smooth'
                })
                egg.classList.add('active')
            }
            else {
                egg.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                })
                egg.classList.remove('active')
            }
        })
    })
}
