const checkbox = document.querySelectorAll('.form-select-adm input[type=checkbox]')
const hiddenItems = document.querySelectorAll('.js-hide')
let checkMark = 0

if (checkbox.length > 0) {
    console.log(checkbox.length)
    for (let i = 0, len = checkbox.length; i < len; i++) {
        if (checkbox[i].type === 'checkbox') {
            console.log(len)

            checkbox[i].addEventListener('change', function () {
                // function
                if (checkbox[i].checked) {
                    checkMark++
                    console.log(checkMark)
                }
                else {
                    checkMark--
                    console.log(checkMark)
                }

                // eslint-disable-next-line eqeqeq
                if (checkMark == len) {
                    console.log(checkMark, len, 'equal')

                    for (var j = 0; j < hiddenItems.length; j++) {
                        if (hiddenItems[j].classList.contains('js-hide')) {
                            console.log(hiddenItems[j].classList.contains('js-hide'))
                            hiddenItems[j].classList.remove('js-hide')
                        }
                    }
                }
                else {
                    for (var k = 0; k < hiddenItems.length; k++) {
                        if (!hiddenItems[k].classList.contains('js-hide')) {
                            console.log(!hiddenItems[k].classList.contains('js-hide'))
                            hiddenItems[k].classList.add('js-hide')
                        }
                    }
                }
                console.log(checkMark, len)
            })
        }
    }

    // Find the form element
    const formRows = document.querySelectorAll('.freeform-row')
    const formWrapper = document.getElementsByClassName('form-select__wrapper')
    const alertBanner = document.getElementsByClassName('alert__wrapper')
    const adminBanner = document.getElementsByTagName('admin-bar')

    function goToTop () {
        var offsetSpace = ''

        function scrollTo () {
            for (var i = 0; i < formWrapper.length; i++) {
                const topPos = formWrapper[i].offsetTop - offsetSpace
                console.log(topPos)
                window.scroll({
                    top: topPos,
                    left: 0,
                    behavior: 'smooth'
                })
            }
        }

        if (window.innerWidth <= 640) {
            if (alertBanner.length > 0) {
                offsetSpace = 140
            }
            if (adminBanner.length > 0 && alertBanner.length > 0) {
                offsetSpace = 189
            }
            // console.log(offsetSpace)
            scrollTo()
        }
        if (window.innerWidth >= 641) {
            if (alertBanner.length > 0) {
                offsetSpace = 110
            }
            else {
                offsetSpace = 50
            }
            if (adminBanner.length > 0 && alertBanner.length > 0) {
                offsetSpace = 159
            }
            // console.log(offsetSpace)
            scrollTo()
        }
    }

    // Do something on a successful ajax submit
    document.addEventListener('freeform-render-success', function (event) {
        goToTop()
        for (let i = 0; i < formRows.length; i++) {
            formRows[i].classList.add('js-hide')
        }
        // console.log('successful!')
    })

}
