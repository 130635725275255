const parentNodes = document.querySelectorAll('.tabs-color__wrapper')

Array.from(parentNodes)
    .forEach((children) => {
        const navNodes = children.querySelectorAll('.tabs-color__link')
        const contentNodes = children.querySelectorAll('.tabs-color__content')

        if (navNodes.length > 1) {
            const nav = Array.from(navNodes)
            const content = Array.from(contentNodes)

            nav.map((link, key) => {
                link.addEventListener('click', () => {
                    if (!link.classList.contains('active')) {
                        nav.map(l => {
                            l.classList.remove('active')
                            l.setAttribute('aria-selected', 'false')
                        })
                        content.map(c => {
                            c.classList.remove('active')
                        })

                        link.classList.add('active')
                        link.setAttribute('aria-selected', 'true')
                        content[key].classList.add('active')
                    }
                })
            })
        }
    })
