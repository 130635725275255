// import smoothscroll from 'smoothscroll-polyfill'
//smoothscroll.polyfill()

const arrowNodes = document.getElementsByClassName('people-preview__arrow')
const backNodes = document.getElementsByClassName('people-preview__arrow-back')
const peopleNodes = document.getElementsByClassName('people-preview__content')

// require('smoothscroll-polyfill').polyfill()
// // import smoothscroll from 'smoothscroll-polyfill';
// //smoothscroll.polyfill();

let scrolled = 0

if (arrowNodes.length > 0) {
    const arrows = Array.from(arrowNodes)
    const backs = Array.from(backNodes)
    const people = Array.from(peopleNodes)

    arrows.map((arrow, key) => {
        arrow.addEventListener('click', () => {
            if (people[key].scrollLeft + people[key].offsetWidth < people[key].scrollWidth) {
                if (window.innerWidth <= 680) {
                    scrolled += 230
                }
                else {
                    scrolled += 480
                }
            }

            people[key].scroll({
                top: 0,
                left: scrolled,
                behavior: 'smooth'
            })
        })

        arrow.addEventListener('keypress', (e) => {
            if (e.charCode === 13) {
                if (people[key].scrollLeft + people[key].offsetWidth < people[key].scrollWidth) {
                    if (window.innerWidth <= 680) {
                        scrolled += 230
                    }
                    else {
                        scrolled += 480
                    }
                }

                people[key].scroll({
                    top: 0,
                    left: scrolled,
                    behavior: 'smooth'
                })
            }
        })
    })

    backs.map((arrow, key) => {
        arrow.addEventListener('click', () => {
            if (scrolled > 0) {
                if (window.innerWidth <= 680) {
                    scrolled -= 230
                }
                else {
                    scrolled -= 480
                }
            }

            people[key].scroll({
                top: 0,
                left: scrolled,
                behavior: 'smooth'
            })
        })

        arrow.addEventListener('keypress', (e) => {
            if (e.charCode === 13) {
                if (scrolled > 0) {
                    if (window.innerWidth <= 680) {
                        scrolled -= 230
                    }
                    else {
                        scrolled -= 480
                    }
                }

                people[key].scroll({
                    top: 0,
                    left: scrolled,
                    behavior: 'smooth'
                })
            }
        })
    })
}
