// import smoothscroll from 'smoothscroll-polyfill'
//smoothscroll.polyfill()

const arrowNodesP = document.getElementsByClassName('previews__arrow')
const backNodesP = document.getElementsByClassName('previews__arrow-back')
const pageNodes = document.getElementsByClassName('previews__list')

// require('smoothscroll-polyfill').polyfill()
// // import smoothscroll from 'smoothscroll-polyfill';
// //smoothscroll.polyfill();

let scrolledP = 0

if (arrowNodesP.length > 0) {
    const arrowsP = Array.from(arrowNodesP)
    const backsP = Array.from(backNodesP)
    const pages = Array.from(pageNodes)

    arrowsP.map((arrow, key) => {
        arrow.addEventListener('click', () => {
            if (pages[key].scrollLeft + pages[key].offsetWidth < pages[key].scrollWidth) {
                scrolledP += 260
            }

            pages[key].scroll({
                top: 0,
                left: scrolledP,
                behavior: 'smooth'
            })
        })

        arrow.addEventListener('keypress', (e) => {
            if (e.charCode === 13) {
                if (pages[key].scrollLeft + pages[key].offsetWidth < pages[key].scrollWidth) {
                    scrolledP += 260
                }

                pages[key].scroll({
                    top: 0,
                    left: scrolledP,
                    behavior: 'smooth'
                })
            }
        })
    })

    backsP.map((arrow, key) => {
        arrow.addEventListener('click', () => {
            if (scrolledP > 0) {
                scrolledP -= 260
            }

            pages[key].scroll({
                top: 0,
                left: scrolledP,
                behavior: 'smooth'
            })
        })

        arrow.addEventListener('keypress', (e) => {
            if (e.charCode === 13) {
                if (scrolledP > 0) {
                    scrolledP -= 260
                }

                pages[key].scroll({
                    top: 0,
                    left: scrolledP,
                    behavior: 'smooth'
                })
            }
        })
    })
}
