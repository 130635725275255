// for custom forms only
const formWrapper = document.getElementsByClassName('form__wrapper')

if (formWrapper.length) {
    const checkboxes = formWrapper[0].querySelectorAll("input[type='checkbox']")

    Array.from(checkboxes).map((c) => {
        const checkbox = document.createElement('span')
        checkbox.classList.add('hidden')

        c.parentNode.appendChild(checkbox)
        c.parentNode.classList.add('checkbox__label')
        c.parentNode.classList.add('custom-form')
    })
}
