(($) => {
    const isTouch = ('ontouchstart' in window
            && !window.navigator.userAgent.match(/X11/))
        || ('DocumentTouch' in window
            && document instanceof window.DocumentTouch)
        || (window.navigator.maxTouchPoints || 0) > 0

    if (isTouch)
        return

    const selectizeInit = () => {
        if (!('Selectize' in window))
            return

        $('.selectize__select select:not(.selectized)')
            .selectize({
                create: false,
                sortField: false,
                onChange: function (val) {
                    const select = $(this)[0].$input[0]

                    if (!select)
                        return

                    select.value = val

                    const evt = !('Event' in window)
                        ? document.createEvent('Event')
                        : new window.Event('change',
                            { view: window, bubbles: true })

                    if (!('Event' in window))
                        evt.initEvent('change', true, true)

                    select.dispatchEvent(evt)
                },
                onInitialize: function () {
                    const select = $(this)[0].$input[0]
                    const instance = $(this)[0]

                    if (!select)
                        return

                    instance.$wrapper[0]
                        .addEventListener('keydown', (e) => {
                            if (!(e.key || e.keyCode || '').match(/tab/i))
                                return

                            const isReverse = e.shiftKey
                            const $tabbableEls = $('body').find(':tabbable')
                            const activeTabIndex = $tabbableEls.index(e.target)

                            let nextTabIndex

                            if (isReverse && !activeTabIndex)
                                nextTabIndex = $tabbableEls.length - 1

                            else if (!isReverse
                                    && activeTabIndex === $tabbableEls.length - 1)
                                nextTabIndex = 0
                            else
                                nextTabIndex = activeTabIndex + (isReverse
                                    ? -1
                                    : 1)

                            if (!nextTabIndex || !$tabbableEls[nextTabIndex])
                                return

                            e.stopPropagation()
                            e.preventDefault()

                            $tabbableEls[nextTabIndex].focus()
                        })

                    const selectObserver = new window
                        .MutationObserver((mutations) => {
                            if (select.disabled && !instance.isDisabled)
                                instance.disable()

                            if (!select.disabled && instance.isDisabled)
                                instance.enable()
                        })

                    selectObserver
                        .observe(select, {
                            attributes: true
                        })
                }
            })
    }

    window.addEventListener('load', () => {
        selectizeInit()

        const dynamicSelectsObserver = new window
            .MutationObserver((mutations) => {
                if (!document.querySelector(
                    '.selectize__select select:not(.selectized)'))
                    return

                selectizeInit()
            })

        dynamicSelectsObserver
            .observe(document.body, {
                childList: true,
                subtree: true
            })
    })
})(window.jQuery)
