const sections = document.getElementsByClassName('giving-impact__section')

if (sections.length) {
    const height = window.innerHeight

    const giCommunity = document.getElementsByClassName('gi__community')
    const giTopics = document.getElementsByClassName('gi__topics')
    const giKG = document.getElementsByClassName('gi__kg')
    const giKGc = document.getElementsByClassName('gi__kgc')
    const giImpact = document.getElementsByClassName('gi__impact')
    const giCelebrate = document.getElementsByClassName('gi__celebrate')
    const giQuotes = document.getElementsByClassName('gi__quotes')
    const giImage = document.getElementsByClassName('gi__image')

    // add animations for each section appearing
    window.addEventListener('scroll', () => {
        Array.from(sections).map((section, key) => {
            const pageTop = section.getBoundingClientRect().top

            if (pageTop < height / 4 * 3 + 80)
                if (!section.classList.contains('active')) {
                    section.classList.add('active')
                    switch (key) {
                    // community
                    case 1:
                        for (let i = 0; i < giCommunity.length; i++)
                            setTimeout(function () {
                                giCommunity[i].classList.add('active')
                            }, 100 * i)

                        break
                        // topics
                    case 2:
                        for (let i = 0; i < giTopics.length; i++)
                            setTimeout(function () {
                                giTopics[i].classList.add('active')
                            }, 100 * i)

                        break
                        // image
                    case 3:
                        for (let i = 0; i < giImage.length; i++)
                            setTimeout(function () {
                                giImage[i].classList.add('active')
                            }, 100 * i)

                        break
                        // rich text
                    case 4:
                        for (let i = 0; i < giKG.length; i++)
                            setTimeout(function () {
                                giKG[i].classList.add('active')
                            }, 100 * i)

                        break

                        // impact
                    case 5:
                        for (let i = 0; i < giImpact.length; i++)
                            setTimeout(function () {
                                giImpact[i].classList.add('active')
                            }, 100 * i)

                        break
                        // celebrate
                    case 6:
                        for (let i = 0; i < giCelebrate.length; i++)
                            setTimeout(function () {
                                giCelebrate[i].classList.add('active')
                            }, 100 * i)

                        break
                        // quotes
                    case 7:
                        for (let i = 0; i < giQuotes.length; i++)
                            setTimeout(function () {
                                giQuotes[i].classList.add('active')
                            }, 100 * i)

                        break
                        // rich text condensed
                    case 8:
                        for (let i = 0; i < giKGc.length; i++)
                            setTimeout(function () {
                                giKGc[i].classList.add('active')
                            }, 100 * i)
                        break
                        // give button
                    case 9:
                        break

                    }
                }
        })
    })
}
