// import smoothscroll from 'smoothscroll-polyfill'
//smoothscroll.polyfill()

const peopleContainer = document.getElementsByClassName('people-mod__main-inner')
const person = document.getElementsByClassName('people-mod__entry')
const clickRegion = document.getElementsByClassName('js__link')
const close = document.getElementsByClassName('modal__close')
const contentMain = document.getElementsByClassName('content__main')
const personOther = document.querySelectorAll('.people-mod__main-inner .people-mod__entry')
const peopleTitle = document.getElementsByClassName('people-mod__header-title')
const alertBanner = document.getElementsByClassName('alert__wrapper')
const adminBanner = document.getElementsByTagName('admin-bar')
// require('smoothscroll-polyfill').polyfill()
// // import smoothscroll from 'smoothscroll-polyfill';
// //smoothscroll.polyfill();

if (peopleContainer.length > 0) {
    $.each(person, function (i) {

        function addHidden () {
            for (var i = 0; i < personOther.length; i++) {
                if (!personOther[i].classList.contains('open')) {
                    personOther[i].classList.add('fadeout')
                    // personOther[i].classList.add('hidden')
                }
            }
        }

        function removeHidden () {
            for (var i = 0; i < personOther.length; i++) {
                if (personOther[i].classList.contains('fadeout')) {
                    // personOther[i].classList.remove('hidden')
                    personOther[i].classList.remove('fadeout')
                }
            }
        }

        function goToTop () {
            var offsetSpace = ''

            function scrollTo () {
                for (var i = 0; i < peopleTitle.length; i++) {
                    const topPos = peopleTitle[i].offsetTop - offsetSpace
                    console.log(topPos)
                    window.scroll({
                        top: topPos,
                        left: 0,
                        behavior: 'smooth'
                    })
                }
            }

            if (window.innerWidth <= 640) {
                if (alertBanner.length > 0) {
                    offsetSpace = 140
                }
                if (adminBanner.length > 0 && alertBanner.length > 0) {
                    offsetSpace = 189
                }
                // console.log(offsetSpace)
                scrollTo()
            }
            if (window.innerWidth >= 641) {
                if (alertBanner.length > 0) {
                    offsetSpace = 110
                }
                else {
                    offsetSpace = 50
                }
                if (adminBanner.length > 0 && alertBanner.length > 0) {
                    offsetSpace = 159
                }
                // console.log(offsetSpace)
                scrollTo()
            }
        }

        // open
        clickRegion[i].addEventListener('click', () => {
            console.log('clicked')
            person[i].classList.add('open')
            person[i].classList.add('modal')
            close[i].classList.remove('hidden')
            contentMain[i].classList.add('fadein')
            addHidden()
            goToTop()
        })
        // close
        close[i].addEventListener('click', () => {
            console.log('close clicked')
            contentMain[i].classList.remove('fadein')
            close[i].classList.add('hidden')
            person[i].classList.remove('modal')
            person[i].classList.remove('open')
            removeHidden()
        })
    })
}
