// import smoothscroll from 'smoothscroll-polyfill'
//smoothscroll.polyfill()

const teamContainer = document.getElementsByClassName('people-team__main-inner')
const teamLayout = document.getElementsByClassName('entry-layout')[0]
const teamPerson = document.getElementsByClassName('people-team__entry')
const clickRegion = document.getElementsByClassName('js__link')
const close = document.getElementsByClassName('modal__close')
const teamWrapper = document.getElementsByClassName('people-team__wrapper')
const teamHeader = document.getElementsByClassName('people-team__header')[0]
const alertBanner = document.getElementsByClassName('alert__wrapper')
const adminBanner = document.getElementsByTagName('admin-bar')
// require('smoothscroll-polyfill').polyfill()
// // import smoothscroll from 'smoothscroll-polyfill';
// //smoothscroll.polyfill();

if (teamContainer.length > 0) {
    $.each(teamPerson, function (i) {
        function goToTop () {
            var offsetSpace = ''

            function scrollTo () {
                for (var i = 0; i < teamWrapper.length; i++) {
                    const topPos = teamWrapper[i].offsetTop - offsetSpace
                    console.log(topPos)
                    window.scroll({
                        top: topPos,
                        left: 0,
                        behavior: 'smooth'
                    })
                }
            }

            if (window.innerWidth <= 640) {
                if (alertBanner.length > 0) {
                    offsetSpace = 140
                }

                if (adminBanner.length > 0 && alertBanner.length > 0) {
                    offsetSpace = 189
                }

                // console.log(offsetSpace)
                scrollTo()
            }
            if (window.innerWidth >= 641) {
                if (alertBanner.length > 0) {
                    offsetSpace = 110
                }
                else {
                    offsetSpace = 50
                }

                if (adminBanner.length > 0 && alertBanner.length > 0) {
                    offsetSpace = 159
                }

                // console.log(offsetSpace)
                scrollTo()
            }
        }

        let modalOpen = ''
        // open
        clickRegion[i].addEventListener('click', () => {
            modalOpen = document.getElementById(clickRegion[i].dataset.modal)
            console.log(clickRegion[i].dataset.modal)
            modalOpen.classList.add('modal', 'fadein')
            modalOpen.classList.remove('fadeout')
            teamHeader.classList.add('fadeout')
            close[i].classList.remove('hidden')
            teamLayout.classList.add('fadeout')
            goToTop()
        })
        // close
        close[i].addEventListener('click', () => {
            console.log('close clicked')
            console.log(modalOpen)
            modalOpen.classList.remove('modal', 'fadein')
            modalOpen.classList.add('fadeout')
            teamHeader.classList.remove('fadeout')
            close[i].classList.add('hidden')
            teamLayout.classList.remove('fadeout')
        })
    })
}
