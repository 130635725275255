const parentNodes = document.querySelectorAll('.tabs-logo__wrapper')

Array.from(parentNodes)
    .forEach((children) => {
        const navNodes = children.getElementsByClassName('tabs-logo__link')
        const contentNodes = children.getElementsByClassName('tabs-logo__content')
        const slider = children.getElementsByClassName('tabs-logo__slider')

        let current = 0

        if (navNodes.length > 1) {
            const nav = Array.from(navNodes)
            const content = Array.from(contentNodes)

            const customWindowSize = 1110
            let interval = (window.innerWidth - 16) / (nav.length * 2)
            let offset = (window.innerWidth - 16) / (nav.length * 2) - 80
            let intervalLarge = (customWindowSize - 16) / (nav.length * 2)
            let offsetLarge = (customWindowSize - 16) / (nav.length * 2) - 80
            slider[0].style.transform = 'translateX(' + offset + 'px)'

            if (window.innerWidth >= customWindowSize)
                slider[0].style.transform = 'translateX(' + offsetLarge + 'px)'

            window.addEventListener('resize', () => {
                interval = (window.innerWidth - 16) / (nav.length * 2)
                offset = ((window.innerWidth - 16) / nav.length * (current + 1)) - interval - 80
                slider[0].style.transform = 'translateX(' + offset + 'px)'

                if (window.innerWidth >= customWindowSize) {
                    intervalLarge = (customWindowSize - 16) / (nav.length * 2)
                    offsetLarge = ((customWindowSize - 16) / nav.length * (current + 1)) - intervalLarge - 65
                    slider[0].style.transform = 'translateX(' + offsetLarge + 'px)'
                }
            })

            nav.map((link, key) => {
                link.addEventListener('click', () => {
                    if (!link.classList.contains('active')) {
                        nav.map(l => {
                            l.classList.remove('active')
                            l.setAttribute('aria-selected', 'false')
                        })
                        content.map(c => {
                            c.classList.remove('active')
                        })

                        current = key

                        link.classList.add('active')
                        link.setAttribute('aria-selected', 'true')
                        content[key].classList.add('active')

                        offset = ((window.innerWidth - 16) / nav.length * (key + 1)) - interval - 80
                        slider[0].style.transform = 'translateX(' + offset + 'px)'

                        if (window.innerWidth >= customWindowSize) {
                            offsetLarge = ((customWindowSize - 16) / nav.length * (key + 1)) - intervalLarge - 65
                            slider[0].style.transform = 'translateX(' + offsetLarge + 'px)'
                        }
                    }
                })
            })
        }
    })
