import "@/css/_index.js";
import "@/js/_index.js";

/**
 * import './js/app.js'
  */

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}
console.log('Hello!')
