const tcNodes = document.querySelectorAll('.tabbed-content__wrapper')
// console.log(tcNodes.length)
if (tcNodes.length >= 1) {
    tcNodes.forEach((tcNode) => {
        // console.log(tcNode)
        tabbedContent(tcNode)
    })
}
function tabbedContent(element) {
    const navNodes = element.getElementsByClassName('tabbed-content__link')
    const contentNodes = element.getElementsByClassName('tabbed-content__content')
    const slider = element.getElementsByClassName('tabbed-content__slider')
    const imageNodes = element.getElementsByClassName('tabbed-content__header-wrapper')

    let current = 0
// let currentImage = ''
    let imageBg = null

    if (navNodes.length > 1) {
        const nav = Array.from(navNodes)
        const content = Array.from(contentNodes)

        if (imageNodes.length > 0)
            imageBg = Array.from(imageNodes)

        let interval = (window.innerWidth - 16) / (nav.length * 2)
        let offset = (window.innerWidth - 16) / (nav.length * 2) - 80

        slider[0].style.transform = 'translateX(' + offset + 'px)'

        // if (imageNodes.length > 0)
        //     currentImage = imageNode[0]

        window.addEventListener('resize', () => {
            interval = (window.innerWidth - 16) / (nav.length * 2)
            offset = ((window.innerWidth - 16) / nav.length * (current + 1)) - interval - 80
            slider[0].style.transform = 'translateX(' + offset + 'px)'
        })

        nav.map((link, key) => {
            link.addEventListener('click', () => {
                if (!link.classList.contains('active')) {
                    nav.map(l => {
                        l.classList.remove('active')
                        l.setAttribute('aria-selected', 'false')
                    })
                    content.map(c => {
                        c.classList.remove('active')
                    })
                    if (imageNodes.length > 0)
                        imageBg.map(i => {
                            i.classList.remove('active')
                        })
                    // if (imageNodes.length > 0)
                    //     if (currentImage !== images[key].style.backgroundImage) {
                    //         images.map(i => {
                    //             i.classList.remove('active')
                    //         })
                    //         images[key].classList.add('active')
                    //     }
                    //
                    current = key
                    // if (imageNodes.length > 0)
                    //     currentImage = images[key].style.backgroundImage
                    link.classList.add('active')
                    link.setAttribute('aria-selected', 'true')
                    content[key].classList.add('active')
                    if (imageNodes.length > 0)
                        imageBg[key].classList.add('active')

                    offset = ((window.innerWidth - 16) / nav.length * (key + 1)) - interval - 80
                    slider[0].style.transform = 'translateX(' + offset + 'px)'
                }
            })
        })
    }
}

// const navNodes = document.getElementsByClassName('tabbed-content__link')
// const contentNodes = document.getElementsByClassName('tabbed-content__content')
// const slider = document.getElementsByClassName('tabbed-content__slider')
// const imageNodes = document.getElementsByClassName('tabbed-content__header-image')
//
// let current = 0
// let currentImage = ''
// let images = null
//
// if (navNodes.length > 1) {
//     const nav = Array.from(navNodes)
//     const content = Array.from(contentNodes)
//
//     if (imageNodes.length > 0)
//         images = Array.from(imageNodes)
//
//     let interval = (window.innerWidth - 16) / (nav.length * 2)
//     let offset = (window.innerWidth - 16) / (nav.length * 2) - 80
//
//     slider[0].style.transform = 'translateX(' + offset + 'px)'
//
//     if (imageNodes.length > 0)
//         currentImage = images[0].style.backgroundImage
//
//     window.addEventListener('resize', () => {
//         interval = (window.innerWidth - 16) / (nav.length * 2)
//         offset = ((window.innerWidth - 16) / nav.length * (current + 1)) - interval - 80
//         slider[0].style.transform = 'translateX(' + offset + 'px)'
//     })
//
//     nav.map((link, key) => {
//         link.addEventListener('click', () => {
//             if (!link.classList.contains('active')) {
//                 nav.map(l => {
//                     l.classList.remove('active')
//                     l.setAttribute('aria-selected', 'false')
//                 })
//                 content.map(c => {
//                     c.classList.remove('active')
//                 })
//                 if (imageNodes.length > 0)
//                     if (currentImage !== images[key].style.backgroundImage) {
//                         images.map(i => {
//                             i.classList.remove('active')
//                         })
//                         images[key].classList.add('active')
//                     }
//
//                 current = key
//                 if (imageNodes.length > 0)
//                     currentImage = images[key].style.backgroundImage
//
//                 link.classList.add('active')
//                 link.setAttribute('aria-selected', 'true')
//                 content[key].classList.add('active')
//
//                 offset = ((window.innerWidth - 16) / nav.length * (key + 1)) - interval - 80
//                 slider[0].style.transform = 'translateX(' + offset + 'px)'
//             }
//         })
//     })
// }
