(() => {
    const popupAttr = 'data-popup'

    const popupInit = () => Array
        .from(document.querySelectorAll(`[${
            popupAttr}]:not([${popupAttr}-listener])`))
        .forEach((el) => {
            el.setAttribute(`${popupAttr}-listener`, true)

            const popup = document.querySelector(el.getAttribute(popupAttr))

            if (!popup) {
                if (el.tagName.match(/button/i))
                    el.disabled = true
                else
                    el.setAttribute('aria-disabled', true)

                return
            }

            if (!popup.id) {
                const s4 = () => (((1 + Math.random()) * 0x10000) | 0)
                    .toString(16)
                    .substring(1)

                popup.id = `popup-${
                    s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`
            }

            el.addEventListener('click', (e) => {
                e.preventDefault()
                e.stopPropagation()

                const isActive = el.getAttribute('aria-describedby')
                    === popup.id

                isActive
                    ? el.removeAttribute('aria-describedby')
                    : el.setAttribute('aria-describedby', popup.id)

                popup.classList[isActive ? 'remove' : 'add']('active')
            })

            const closePopupHandler = (e) => {
                if (!el.getAttribute('aria-describedby')
                        || !e.target
                        || e.target === popup
                        || popup.contains(e.target))
                    return

                el.click()
            }

            window
                .addEventListener('click', (e) => closePopupHandler(e))
        })

    window.addEventListener('load', () => {
        popupInit()

        const dynamicPopupObserver = new window
            .MutationObserver((mutations) => {
                if (!document.querySelector(
                    '.selectize__select select:not(.selectized)'))
                    return

                popupInit()
            })

        dynamicPopupObserver
            .observe(document.body, {
                childList: true,
                subtree: true
            })
    })
})()
