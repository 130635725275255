// import smoothscroll from 'smoothscroll-polyfill'
//smoothscroll.polyfill()
const questions = document.getElementsByClassName('faq__subtitle-left')
const headings = document.getElementsByClassName('faq__subtitle-right')
// require('smoothscroll-polyfill').polyfill()
// // import smoothscroll from 'smoothscroll-polyfill';
// //smoothscroll.polyfill();

if (questions.length) {
    const qs = Array.from(questions)
    const hs = Array.from(headings)
    const ans = Array.from(document.getElementsByClassName('faq__answer'))
    const entrys = Array.from(document.getElementsByClassName('faq__entry'))
    const icons = Array.from(document.getElementsByClassName('faq__icon'))

    hs.map((h, key) => {
        h.addEventListener('click', () => {
            if (!h.classList.contains('active')) {
                hs.map(h => {
                    h.classList.remove('active')
                })
                h.classList.add('active')
                const top = entrys[key].offsetTop - 50
                window.scroll({
                    top: top,
                    left: 0,
                    behavior: 'smooth'
                })
            }
        })
    })

    qs.map((q, key) => {
        q.addEventListener('click', () => {
            if (ans[key].classList.contains('active')) {
                ans[key].classList.remove('active')
                icons[key].innerHTML = '+'
            }
            else {
                ans[key].classList.add('active')
                icons[key].innerHTML = '-'
            }
        })
    })
}
